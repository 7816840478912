<template>
  <transition duration="800">
    <div v-if="isMounted" class="error">
      <main>
        <section class="section-main">
          <b-container class="container-error">
            <b-row>
              <b-col>
                <inline-svg
                  :src="require('@/assets/svg/header-logo.svg')"
                  width="200"
                  height="200"
                  aria-label="Macau Grand Prix Museum icon"
                  aria-hidden="true"
                  class="svg-tertiary logo"
                />
                <h3 v-if="$store.state.appErrorMessage">
                  Trouble loading site, please try again!
                </h3>
              </b-col>
            </b-row>
          </b-container>
        </section>
      </main>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Error',
  data: function () {
    return {
      isMounted: false
    }
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss" scoped>
.error {
  color: white;

  .section-main {
    padding: 0 !important;
    // height: 100vh;
    // width: 100vw;
  }

  h3 {
    line-height: 1.4em;
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0 !important;
    height: 100vh;
    width: 100vw;
  }
}
</style>
<style lang="scss">
.error {
  .logo {
    width: 250px;
    height: 250px;

    .fill-to-change {
      fill: currentColor !important;
    }
  }
}
</style>
